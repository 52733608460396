import React from "react";

// Styles
import "./Checklist.scss";

// Components
import Switch from "../../../components/Forms/Switch";

const Checklist = ({ onChange, values }) => (
  <div className="checklist">
    <div className="checklist-header">
      <h3 className="checklist-title">Checklist</h3>
    </div>
    <div className="checklist-content">
      <ul className="checklist-options">
        {/* Read me */}
        <li className="checklist-option">
          <Switch
            name="readme"
            value={values.readme || false}
            label="Has Readme -file?"
            onChange={() =>
              onChange({
                name: "readme",
                value: !values.readme
              })
            }
          />
        </li>

        {/* Domain */}
        <li className="checklist-option">
          <Switch
            name="domain"
            value={values.domain || false}
            label="Has domain?"
            onChange={() =>
              onChange({
                name: "domain",
                value: !values.domain
              })
            }
          />
        </li>

        {/* Meta Tags */}
        <li className="checklist-option">
          <Switch
            name="metatags"
            value={values.metatags || false}
            label="Has Meta Tags?"
            onChange={() =>
              onChange({
                name: "metatags",
                value: !values.metatags
              })
            }
          />
        </li>

        {/* Analytics */}
        <li className="checklist-option">
          <Switch
            name="analytics"
            value={values.analytics || false}
            label="Has analytics scripts?"
            onChange={() =>
              onChange({
                name: "analytics",
                value: !values.analytics
              })
            }
          />
        </li>

        {/* Share image */}
        <li className="checklist-option">
          <Switch
            name="shareImage"
            value={values.shareImage || false}
            label="Has share image?"
            onChange={() =>
              onChange({
                name: "shareImage",
                value: !values.shareImage
              })
            }
          />
        </li>

        {/* Deploy scripts */}
        <li className="checklist-option">
          <Switch
            name="deployScripts"
            value={values.deployScripts || false}
            label="Has deploy scripts?"
            onChange={() =>
              onChange({
                name: "deployScripts",
                value: !values.deployScripts
              })
            }
          />
        </li>
      </ul>
    </div>
  </div>
);

Checklist.displayName = "Checklist";

export default Checklist;
