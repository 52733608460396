import React, { useContext } from "react";

// Styles
import "./LayoutSwitch.scss";

// Store
import { Context } from "../../../store";
import { CHANGE_LAYOUT_ACTION } from "../../../store/actions/layout";

const LayoutOption = ({ layout, value, onClick, icon }) => (
  <span
    className={`layout-switch-toggle${
      layout === value ? " switch-selected" : ""
    }`}
  >
    <i className="material-icons" onClick={() => onClick(value)}>
      {icon}
    </i>
  </span>
);

LayoutOption.displayName = "LayoutOption";

const LayoutSwitch = () => {
  const {
    state: { layout },
    dispatch
  } = useContext(Context);

  const changeLayout = view => {
    dispatch({
      type: CHANGE_LAYOUT_ACTION,
      payload: view
    });
  };

  return (
    <div className="layout-switch">
      <LayoutOption
        layout={layout}
        value="grid"
        icon="apps"
        onClick={changeLayout}
      />
      <LayoutOption
        layout={layout}
        value="list"
        icon="list"
        onClick={changeLayout}
      />
    </div>
  );
};

LayoutSwitch.displayName = "LayoutSwitch";

export default LayoutSwitch;
