import React from "react";

// Style
import "./StagingUrl.scss";

// Components
import Error from "../../../components/Forms/Error";
import Label from "../../../components/Forms/Label";
import TextInput from "../../../components/Forms/TextInput";

const StagingUrl = ({ error, value, onChange }) => (
  <>
    <div className="col-xs-6">
      <Label labelFor="stagingUrl">Staging URL</Label>
      <TextInput
        type="text"
        name="stagingUrl"
        value={value}
        placeholder="https://staging.project.com"
        onChange={e => onChange(e.target.value)}
      />
      {error && (
        <Error>
          <div>{error}</div>
        </Error>
      )}
    </div>
  </>
);

StagingUrl.displayName = "StagingUrl";

export default StagingUrl;
