import { GET_CLIENTS_ACTION } from "../actions/clients";

export default {
  [GET_CLIENTS_ACTION]: (state, { payload }) => {
    return {
      ...state,
      clients: payload
    };
  }
};
