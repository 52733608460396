import React, { useContext, useEffect } from "react";
import _ from "lodash";

// Style
import "./Search.scss";

// Components
import Navbar from "../../components/Headers/Navbar";
import Loader from "../../components/Loader";
import ProjectsList from "../../components/Projects/List";
import ProjectsCards from "../../components/Projects/Cards";

// Store
import { Context } from "../../store";
import { SEARCH_PROJECTS_BY_CLIENT_ACTION } from "../../store/actions/projects";

// GraphQL
import { useClient } from "../../graphql/gql";
import { SEARCH_PROJECTS_BY_CLIENT_QUERY } from "../../graphql/queries/projects";

const Search = ({ match, history }) => {
  const gqlClient = useClient();
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    getClientProjects();
  }, []);

  const getClientProjects = async () => {
    const variables = {
      clientId: match.params.id
    };
    const { searchProjectsByClientQuery } = await gqlClient.request(
      SEARCH_PROJECTS_BY_CLIENT_QUERY,
      variables
    );
    dispatch({
      type: SEARCH_PROJECTS_BY_CLIENT_ACTION,
      payload: searchProjectsByClientQuery
    });
  };

  const Layout = () => (
    <div className="search">
      {state.layout === "list" ? <ProjectsList /> : <ProjectsCards />}
    </div>
  );

  const getClientName = _.head(_.uniq(_.map(state.projects, "client.name")));

  return (
    <>
      <header role="banner">
        <Navbar />
      </header>
      <main role="main">
        <div className="search">
          {state.projects.length > 0 ? (
            <>
              <div className="search-header">
                <h3 className="search-title">
                  Search results for: {getClientName}
                </h3>
              </div>
              {state.layout === "list" ? <ProjectsList /> : <ProjectsCards />}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </main>
    </>
  );
};

Search.displayName = "Search";

export default Search;
