import React, { useState } from "react";

// Styles
import "./FloatingMenu.scss";

const FloatingMenu = ({ MenuButton, menuItems }) => {
  const [showMenu, toggleMenu] = useState(false);

  const displayMenu = () => {
    toggleMenu(true);
  };

  const dismissMenu = () => {
    toggleMenu(false);
  };

  return (
    <div className="floating-menu">
      {/* Button */}
      <MenuButton
        menuState={showMenu}
        changeMenuState={!showMenu ? displayMenu : dismissMenu}
      />
      {/* Menu */}
      {showMenu && (
        <div className="floating-menu-list">
          <ul>
            {menuItems.map((item, index) => (
              <li key={index}>
                <span
                  className="floating-menu-item"
                  onClick={() => {
                    dismissMenu();
                    item.action(item);
                  }}
                >
                  {item.text}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

FloatingMenu.displayName = "FloatingMenu";

export default FloatingMenu;
