import { createContext } from "react";

// Reducers
import rootReducer from "./reducers";
export const Reducer = rootReducer;

// Context
export const Context = createContext({
  user: {},
  clients: [],
  projects: [],
  employees: [],
  layout: "list",
  view: "active",
  auth: false
});
