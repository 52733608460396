import React from "react";

// Style
import "./ProductionUrl.scss";

// Components
import Error from "../../../components/Forms/Error";
import Label from "../../../components/Forms/Label";
import TextInput from "../../../components/Forms/TextInput";

const ProductionUrl = ({ error, value, onChange }) => (
  <>
    <div className="col-xs-6">
      <Label labelFor="productionUrl">Production URL</Label>
      <TextInput
        type="text"
        name="productionUrl"
        value={value}
        placeholder="https://prod.project.com"
        onChange={e => onChange(e.target.value)}
      />
      {error && (
        <Error>
          <div>{error}</div>
        </Error>
      )}
    </div>
  </>
);

ProductionUrl.displayName = "ProductionUrl";

export default ProductionUrl;
