import React from "react";

// Styles
import "./Switch.scss";

const Switch = ({ label, name, value, onChange }) => (
  <div className={`switch${value ? " checked" : ""}`}>
    <label>
      <input
        name={name}
        value={value}
        checked={value}
        type="checkbox"
        onChange={event => {
          const { persist, target } = event;
          onChange({
            persist,
            target: {
              name: target.name,
              value: !value
            }
          });
        }}
      />
      <span className="lever"></span>
      {label}
    </label>
  </div>
);

Switch.displayName = "Switch";

export default Switch;
