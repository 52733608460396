export const GET_EMPLOYEES_QUERY = `
  {
    employeesQuery {
      _id
      name,
      email,
      picture
    }
  }
`;
