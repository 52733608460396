import React from "react";
import { format } from "date-fns";

// Style
import "./Deadline.scss";

// Components
import Error from "../../../components/Forms/Error";
import Label from "../../../components/Forms/Label";
import TextInput from "../../../components/Forms/TextInput";

const Deadline = ({ error, value, onChange }) => (
  <div className="row">
    <div className="col-xs-12">
      <Label labelFor="deadline">Deadline</Label>
      <TextInput
        type="text"
        name="deadline"
        value={value}
        placeholder={format(new Date(), "dd.MM.yyyy")}
        onChange={e => onChange(e.target.value)}
      />
      {error && (
        <Error>
          <div>{error}</div>
        </Error>
      )}
    </div>
  </div>
);

Deadline.displayName = "Deadline";

export default Deadline;
