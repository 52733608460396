import React from "react";

// Styles
import "./Error.scss";

const Error = ({ children }) => {
  return <div className="error">{children}</div>;
};

Error.displayName = "Error";

export default Error;
