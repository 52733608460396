import React from "react";

// Style
import "./BitbucketId.scss";

// Components
import Error from "../../../components/Forms/Error";
import Label from "../../../components/Forms/Label";
import TextInput from "../../../components/Forms/TextInput";

const BitbucketId = ({ error, value, onChange }) => (
  <div className="row">
    <div className="col-xs-12">
      <Label labelFor="bitbucketId">BitbucketId</Label>
      <TextInput
        type="text"
        name="bitbucketId"
        value={value}
        placeholder="example-project"
        onChange={e => onChange(e.target.value)}
      />
    </div>
    {error && (
      <Error>
        <div className="col-xs-12">{error}</div>
      </Error>
    )}
  </div>
);

BitbucketId.displayName = "BitbucketId";

export default BitbucketId;
