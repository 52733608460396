import React from "react";

// Styles
import "./TextInput.scss";

const TextInput = ({ type, name, value, placeholder, onChange }) => (
  <>
    <input
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  </>
);

TextInput.displayName = "TextInput";

export default TextInput;
