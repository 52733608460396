import {
  LOGIN_USER_ACTION,
  LOGOUT_USER_ACTION,
  CHANGE_AUTH_STATE_ACTION
} from "../actions/user";

export default {
  [LOGIN_USER_ACTION]: (state, { payload }) => {
    return {
      ...state,
      user: payload
    };
  },
  [LOGOUT_USER_ACTION]: (state, { payload }) => {
    return {
      ...state,
      user: null,
      auth: false
    };
  },
  [CHANGE_AUTH_STATE_ACTION]: (state, { payload }) => {
    return {
      ...state,
      auth: payload
    };
  }
};
