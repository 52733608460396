import React from "react";

// Style
import "./Notifications.scss";

// Components
import Switch from "../../../components/Forms/Switch";

const Notifications = ({ value, onChange }) => (
  <div className="row">
    <div className="col-xs-12">
      <Switch
        name="notify"
        value={value}
        onChange={() => onChange(!value)}
        label="Enable email notifications"
      />
    </div>
  </div>
);

Notifications.displayName = "Notifications";

export default Notifications;
