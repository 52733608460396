import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { format, fromUnixTime } from "date-fns";

// Styles
import "./List.scss";

// Utils
import { daysLeftBeforeDeadline } from "../../../utils";

// Store
import { Context } from "../../../store";

// Components
import Author from "../Author";
import Actions from "../Actions";
import Loader from "../../Loader";

const Row = ({ userId, project }) => {
  const [visible, setVisibility] = useState(false);

  const showDate = () => {
    setVisibility(true);
  };

  const hideDate = () => {
    setVisibility(false);
  };

  const getProjectActualDeadline = unix => {
    return format(fromUnixTime(unix), "dd.MM.yyyy");
  };

  return (
    <tr className="project-row">
      <td>
        <div className="project-row-content">
          {/* Author */}
          <Author owner={project.author._id === userId} />
          {/* Project name */}
          <div className="project-name">
            <Link to={`/p/${project._id}`}>{project.name}</Link>
          </div>
        </div>
      </td>

      {/* Client name */}
      <td>
        <div className="project-row-content">
          <div className="client-name">
            <Link to={`/q/${project.client._id}`}>{project.client.name}</Link>
          </div>
        </div>
      </td>

      {/* Employees */}
      <td>
        <div className="project-row-content">
          {project.employees.map(({ role, user: { picture } }, index) => {
            if (role !== "reviewer") {
              return (
                <span
                  key={index}
                  className="person-bubble"
                  style={{ backgroundImage: `url(${picture})` }}
                ></span>
              );
            } else {
              return null;
            }
          })}
        </div>
      </td>

      {/* Reviewers */}
      <td>
        <div className="project-row-content">
          {project.employees.map(({ role, user: { picture } }, index) => {
            if (role === "reviewer") {
              return (
                <span
                  key={index}
                  className="person-bubble"
                  style={{ backgroundImage: `url(${picture})` }}
                ></span>
              );
            } else {
              return null;
            }
          })}
        </div>
      </td>

      {/* Status */}
      <td>
        <div className="project-row-content">
          <span className={`project-status status-${project.status}`}>
            {project.status === "completed"
              ? "Completed"
              : `In ${project.status}`}
          </span>
        </div>
      </td>

      {/* Updated At */}
      <td>
        <div className="project-row-content">
          <span className="project-last-modified">
            {format(Number(project.updatedAt), "MMM d")}
          </span>
        </div>
      </td>

      {/* Deadline */}
      <td>
        <div className="project-row-conent">
          <span
            className="project-deadline"
            onMouseEnter={showDate}
            onMouseLeave={hideDate}
          >
            {daysLeftBeforeDeadline(project.deadline)}
          </span>
        </div>
      </td>

      {/* Actions */}
      <td>
        <Actions project={project} />
      </td>
    </tr>
  );
};

Row.displayName = "Row";

const List = () => {
  const { state } = useContext(Context);
  return (
    <table className="projects-list">
      <thead>
        <tr>
          <th>Name</th>
          <th>Client</th>
          <th>Employees</th>
          <th>Reviewers</th>
          <th>Status</th>
          <th>Last modified</th>
          <th>Deadline</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.projects ? (
          state.projects.map((project, index) => (
            <Row key={index} project={project} userId={state.user._id} />
          ))
        ) : (
          <Loader />
        )}
      </tbody>
    </table>
  );
};

List.displayName = "List";

export default List;
