import React, { useContext } from "react";
import { GraphQLClient } from "graphql-request";
import { GoogleLogin } from "react-google-login";

// Styles
import "./Login.scss";

// Store
import { Context } from "../../store";
import {
  LOGIN_USER_ACTION,
  CHANGE_AUTH_STATE_ACTION
} from "../../store/actions/user";

// GraphQL
import { ENDPOINT } from "../../graphql/gql";
import { GET_USER_QUERY } from "../../graphql/queries/user";

const Login = () => {
  const { dispatch } = useContext(Context);

  const onFailure = res => {
    if (res) {
      console.log("Error logging in");
      console.log(res);
    }
    dispatch({
      type: CHANGE_AUTH_STATE_ACTION,
      payload: false
    });
  };

  const onSuccess = async googleUser => {
    try {
      const idToken = googleUser.getAuthResponse().id_token;
      const gqlClient = new GraphQLClient(ENDPOINT, {
        headers: {
          authorization: idToken
        }
      });
      const { user } = await gqlClient.request(GET_USER_QUERY);
      dispatch({
        type: LOGIN_USER_ACTION,
        payload: user
      });
      dispatch({
        type: CHANGE_AUTH_STATE_ACTION,
        payload: googleUser.isSignedIn()
      });
    } catch (err) {
      onFailure(err);
    }
  };

  return (
    <main role="main" className="login">
      <div className="login-content">
        <div className="login-header">
          <h1 className="login-title">Vandal</h1>
        </div>
        <GoogleLogin
          theme="dark"
          isSignedIn={true}
          onSuccess={onSuccess}
          onFailure={onFailure}
          buttonText="Login with Google"
          clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
        />
      </div>
    </main>
  );
};

Login.displayName = "Login";

export default Login;
