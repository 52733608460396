import React from "react";

// Styles
import "./Navbar.scss";

// Components
import Logo from "../Logo";
import UserMenu from "../../Menus/UserMenu";
import ProjectMenu from "../../Menus/ProjectMenu";

const Navbar = ({ showProjectMenu }) => {
  return (
    <section className="navbar">
      {/* Logo */}
      <Logo />

      {/*
        Project navigation:
        Only needed in certain pages
      */}
      {showProjectMenu && <ProjectMenu />}

      {/* User navigation */}
      <UserMenu />
    </section>
  );
};

Navbar.displayName = "Navbar";

export default Navbar;
