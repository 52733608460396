import {
  GET_PROJECT_ACTION,
  GET_PROJECTS_ACTION,
  CREATE_PROJECT_ACTION,
  DELETE_PROJECT_ACTION,
  SET_PROJECT_TO_REVIEW_ACTION,
  GET_READ_ONLY_PROJECT_ACTION,
  SEARCH_PROJECTS_BY_CLIENT_ACTION
} from "../actions/projects";

export default {
  [GET_PROJECT_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: { ...payload }
    };
  },
  [GET_PROJECTS_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: payload
    };
  },
  [CREATE_PROJECT_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: [...state.projects, payload]
    };
  },
  [DELETE_PROJECT_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: state.projects.filter(project => project._id !== payload._id)
    };
  },
  [SET_PROJECT_TO_REVIEW_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: state.projects.map(project => {
        if (project._id === payload) {
          project.status = "review";
        }
        return project;
      })
    };
  },
  [GET_READ_ONLY_PROJECT_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: payload
    };
  },
  [SEARCH_PROJECTS_BY_CLIENT_ACTION]: (state, { payload }) => {
    return {
      ...state,
      projects: payload
    };
  }
};
