import { CHANGE_LAYOUT_ACTION, SET_VIEW_TYPE_ACTION } from "../actions/layout";

export default {
  [CHANGE_LAYOUT_ACTION]: (state, { payload }) => {
    return {
      ...state,
      layout: payload
    };
  },
  [SET_VIEW_TYPE_ACTION]: (state, { payload }) => {
    return {
      ...state,
      view: payload
    };
  }
};
