import React from "react";
import { format } from "date-fns";

// Styles
import "./Summary.scss";

// Utils
import { daysLeftBeforeDeadline } from "../../../utils";

const Summary = ({
  name,
  client,
  deadline,
  createdAt,
  updatedAt,
  bitbucketId,
  productionUrl,
  stagingUrl
}) => (
  <div className="summary">
    <div className="summary-header">
      <h3 className="summary-title">Summary</h3>
    </div>
    <div className="summary-content">
      <div className="row">
        <div className="col-xs-3">
          {/* Project name */}
          <div className="summary-field summary-field-top">
            <div className="field-title">Project name</div>
            <div className="field-value">{name}</div>
          </div>
          {/* Production URL */}
          <div className="summary-field summary-field-bottom">
            <div className="field-title">Production URL</div>
            <div className="field-value">
              <a
                target="_blank"
                href={`${productionUrl}`}
                rel="noopener noreferrer"
              >
                {productionUrl}
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="row">
            <div className="col-xs-12">
              {/* Client name */}
              <div className="summary-field summary-field-top">
                <div className="field-title">Client</div>
                <div className="field-value">{client.name}</div>
              </div>
              {/* Staging URL */}
              <div className="summary-field summary-field-bottom">
                <div className="field-title">Staging URL</div>
                <div className="field-value">
                  <a
                    target="_blank"
                    href={`${stagingUrl}`}
                    rel="noopener noreferrer"
                  >
                    {stagingUrl}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-6">
          <div className="row">
            {/* Created at */}
            <div className="col-xs-4">
              <div className="summary-field summary-field-top">
                <div className="field-title">Created at</div>
                <div className="field-value">
                  {format(Number(createdAt), "MMM d, yyyy")}
                </div>
              </div>
            </div>
            {/* Updated at */}
            <div className="col-xs-4">
              <div className="summary-field summary-field-top">
                <div className="field-title">Updated at</div>
                <div className="field-value">
                  {format(Number(updatedAt), "MMM d, yyyy")}
                </div>
              </div>
            </div>
            {/* Deadline */}
            <div className="col-xs-4">
              <div className="summary-field summary-field-top">
                <div className="field-title">Deadline</div>
                <div className="field-value">
                  {daysLeftBeforeDeadline(deadline)}
                </div>
              </div>
            </div>
          </div>
          {/* Staging URL */}
          <div className="row">
            <div className="col-xs-12">
              <div className="summary-field summary-field-bottom">
                <div className="field-title">Bitbucket</div>
                <div className="field-value">
                  <a
                    target="_blank"
                    href={`https://bitbucket.org/${bitbucketId}`}
                    rel="noopener noreferrer"
                  >
                    {`https://bitbucket.org/${bitbucketId}`}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Summary.displayName = "Summary";

export default Summary;
