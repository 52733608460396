import React from "react";
import { withRouter } from "react-router-dom";

import "./Logo.scss";

const Logo = ({ history }) => {
  const routeMiddleware = () => {
    history.push("/");
  };

  return (
    <div className="logo" onClick={routeMiddleware}>
      <h1>Vandal</h1>
    </div>
  );
};

Logo.displayName = "Logo";

export default withRouter(Logo);
