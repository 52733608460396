import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

// Styles
import "./Actions.scss";

// Store
import { Context } from "../../../store";
import {
  GET_PROJECTS_ACTION,
  DELETE_PROJECT_ACTION
} from "../../../store/actions/projects";

// GraphQL
import { useClient } from "../../../graphql/gql";
import {
  DELETE_PROJECT_MUTATION,
  ARCHIVE_PROJECT_MUTATION,
  SET_PROJECT_TO_REVIEW_MUTATION
} from "../../../graphql/mutations/projects";
import { GET_ACTIVE_PROJECTS_QUERY } from "../../../graphql/queries/projects";

// Components
import Modal from "../../Modal";
import FloatingMenu from "../../Menus/FloatingMenu";

const ActionsButton = ({ changeMenuState }) => {
  const handleButtonClick = () => {
    changeMenuState();
  };
  return (
    <div className="project-actions" onClick={handleButtonClick}>
      <i className="material-icons">more_vert</i>
    </div>
  );
};

ActionsButton.displayName = "ActionsButton";

const checkIfUserAlreadyReviewer = (userId, project) => {
  return (
    project.employees.filter(({ role, user }) => {
      return user._id === userId && role === "reviewer";
    }).length > 0
  );
};

const Actions = ({ project, history }) => {
  const gqlClient = useClient();
  const { state, dispatch } = useContext(Context);
  const [prompt, showPrompt] = useState(false);

  const reviewProject = async () => {
    const userIsReviewer = checkIfUserAlreadyReviewer(state.user._id, project);
    const variables = {
      projectId: project._id
    };

    if (!userIsReviewer) {
      variables.userId = state.user._id;
    }

    // Set project for review
    await gqlClient.request(SET_PROJECT_TO_REVIEW_MUTATION, variables);
    history.push(`/review/${project._id}`);
  };

  const archiveProject = async () => {
    const variables = {
      projectId: project._id,
      archived: !project.archived
    };
    // Archive current project
    await gqlClient.request(ARCHIVE_PROJECT_MUTATION, variables);
    // Fetch new list of active projects
    const { activeProjectsQuery } = await gqlClient.request(
      GET_ACTIVE_PROJECTS_QUERY
    );
    dispatch({
      type: GET_PROJECTS_ACTION,
      payload: activeProjectsQuery
    });
  };

  const deleteProject = async () => {
    dismissPrompt();
    const variables = {
      projectId: project._id
    };
    const { deleteProjectMutation } = await gqlClient.request(
      DELETE_PROJECT_MUTATION,
      variables
    );
    dispatch({
      type: DELETE_PROJECT_ACTION,
      payload: deleteProjectMutation
    });
  };

  const displayPrompt = () => {
    showPrompt(true);
  };

  const dismissPrompt = () => {
    showPrompt(false);
  };

  // Menu items based by state
  const menuItems = {
    review: {
      text: "Review",
      action: reviewProject
    },
    archive: {
      text: "Archive",
      action: archiveProject
    },
    revive: {
      text: "Revive",
      action: archiveProject
    },
    drop: {
      text: "Delete",
      action: displayPrompt
    }
  };

  const menu = _(menuItems)
    .pick(
      !project.archived ? ["review", "archive", "drop"] : ["revive", "drop"]
    )
    .map(item => {
      return { ...item };
    })
    .value();

  return (
    <div className="actions-menu">
      {/* Menu */}
      <FloatingMenu menuItems={menu} MenuButton={ActionsButton} />

      {/* Modal */}
      {prompt && (
        <Modal
          states={{
            success: {
              text: "Yes",
              action: deleteProject
            },
            cancel: {
              text: "No",
              action: dismissPrompt
            }
          }}
        >
          <h2 className="modal-title">You're about to delete a project.</h2>
          <p className="modal-text">Are you sure you want to proceed?</p>
        </Modal>
      )}
    </div>
  );
};

Actions.displayName = "Actions";

export default withRouter(Actions);
