import { GET_EMPLOYEES_ACTION } from "../actions/employees";

export default {
  [GET_EMPLOYEES_ACTION]: (state, { payload }) => {
    return {
      ...state,
      employees: payload
    };
  }
};
