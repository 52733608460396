import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { parse, getUnixTime } from "date-fns";
import _ from "lodash";

// Styles
import "./NewProject.scss";

// Hooks
import useValidation from "../../hooks/useValidation";

// Store
import { Context } from "../../store";
import { GET_CLIENTS_ACTION } from "../../store/actions/clients";
import { GET_EMPLOYEES_ACTION } from "../../store/actions/employees";
import { CREATE_PROJECT_ACTION } from "../../store/actions/projects";

// GraphQL
import { useClient } from "../../graphql/gql";
import { GET_CLIENTS_QUERY } from "../../graphql/queries/clients";
import { GET_EMPLOYEES_QUERY } from "../../graphql/queries/employees";
import { CREATE_PROJECT_MUTATION } from "../../graphql/mutations/projects";

// Components
import Navbar from "../../components/Headers/Navbar";
import Button from "../../components/Button";

import ProjectName from "./ProjectName";
import Client from "./Client";
import BitbucketId from "./BitbucketId";
import ProductionUrl from "./ProductionUrl";
import StagingUrl from "./StagingUrl";
import Notifications from "./Notifications";
import Employees from "./Employees";
import Deadline from "./Deadline";

const NewProject = ({ history }) => {
  const gqlClient = useClient();
  const { state, dispatch } = useContext(Context);

  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [bitbucketId, setBitbucketId] = useState("");
  const [productionUrl, setProductionUrl] = useState("");
  const [stagingUrl, setStagingUrl] = useState("");
  const [notify, setNotify] = useState(false);
  const [deadline, setDeadline] = useState("");
  const [employees, setEmployees] = useState([]);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { validateNewProject } = useValidation();

  // Get clients
  useEffect(() => {
    getQueryData();
  }, []);

  useEffect(() => {
    if (_.isEmpty(errors) && isSubmitting) {
      createNewProject();
    }
    setIsSubmitting(false);
  }, [errors]);

  const getQueryData = async () => {
    // clients
    const { clientsQuery } = await gqlClient.request(GET_CLIENTS_QUERY);
    dispatch({
      type: GET_CLIENTS_ACTION,
      payload: clientsQuery
    });
    // employees
    const { employeesQuery } = await gqlClient.request(GET_EMPLOYEES_QUERY);
    dispatch({
      type: GET_EMPLOYEES_ACTION,
      payload: employeesQuery
    });
  };

  const handleDiscard = () => {
    history.push("/");
  };

  const handleSubmit = event => {
    event.preventDefault();
    const values = {
      name,
      client,
      deadline,
      bitbucketId,
      productionUrl,
      stagingUrl,
      employees
    };
    setIsSubmitting(true);
    setErrors(validateNewProject(values));
  };

  const createNewProject = async () => {
    try {
      const variables = {
        name,
        bitbucketId,
        productionUrl,
        stagingUrl,
        employees,
        notify,
        deadline: getUnixTime(
          parse(deadline, "dd.MM.yyyy", new Date())
        ).toString(),
        client: client._id,
        author: state.user._id,
        status: "queue",
        archived: false
      };

      const { newProjectMutation } = await gqlClient.request(
        CREATE_PROJECT_MUTATION,
        variables
      );

      dispatch({
        type: CREATE_PROJECT_ACTION,
        payload: newProjectMutation
      });

      history.push("/");
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <>
      <header role="banner">
        <Navbar />
      </header>
      <main role="main" className="page new-project">
        <h2 className="page-title">New project</h2>
        <div className="page-content">
          <form className="form">
            {/* Project name */}
            <ProjectName error={errors.name} value={name} onChange={setName} />

            {/* Client name */}
            <Client
              error={errors.client}
              value={client}
              clients={state.clients}
              onChange={setClient}
            />

            {/* Bitbucket Id */}
            <BitbucketId
              error={errors.bitbucketId}
              value={bitbucketId}
              onChange={setBitbucketId}
            />

            {/* Urls */}
            <div className="row">
              {/* Production Url */}
              <ProductionUrl
                error={errors.productionUrl}
                value={productionUrl}
                onChange={setProductionUrl}
              />
              {/* Staging Url */}
              <StagingUrl
                error={errors.stagingUrl}
                value={stagingUrl}
                onChange={setStagingUrl}
              />
            </div>

            {/* Notifications */}
            <Notifications value={notify} onChange={setNotify} />

            {/* Deadline */}
            <Deadline
              error={errors.deadline}
              value={deadline}
              onChange={setDeadline}
            />

            {/* Employees */}
            <Employees
              values={employees}
              error={errors.employees}
              employees={state.employees}
              setEmployees={setEmployees}
            />

            {/* Buttons */}
            <div className="row">
              <div className="col-xs-12">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  classNames="btn btn-cyan"
                >
                  Create
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleDiscard}
                  classNames="btn btn-purple"
                >
                  Discard
                </Button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

NewProject.displayName = "NewProject";

export default withRouter(NewProject);
