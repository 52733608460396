export const CREATE_PROJECT_MUTATION = `
  mutation(
    $name: String!
    $client: ID!
    $author: ID!
    $employees: [EmployeeInput]
    $bitbucketId: String!
    $productionUrl: String!
    $stagingUrl: String!
    $notify: Boolean!
    $deadline: String!
    $status: String!
    $archived: Boolean!
  ) {
    newProjectMutation(
      input: {
        name: $name
        client: $client
        author: $author
        employees: $employees
        bitbucketId: $bitbucketId
        productionUrl: $productionUrl
        stagingUrl: $stagingUrl
        notify: $notify
        deadline: $deadline
        status: $status
        archived: $archived
      }
    ) {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          name
        }
      }
      status
      deadline
    }
  }
`;

export const DELETE_PROJECT_MUTATION = `
  mutation($projectId: ID!) {
    deleteProjectMutation(projectId: $projectId) {
      _id
    }
  }
`;

export const ARCHIVE_PROJECT_MUTATION = `
  mutation(
    $projectId: ID!
    $archived: Boolean!
  ) {
    archiveProjectMutation(
      projectId: $projectId
      archived: $archived
    ) {
      _id
    }
  }
`;

export const SET_PROJECT_TO_REVIEW_MUTATION = `
  mutation(
    $projectId: ID!
    $userId: ID
  ) {
    setProjectToReviewMutation(
      projectId: $projectId
      userId: $userId
    ) {
      _id
    }
  }
`;

export const SAVE_PROJECT_REVIEW_MUTATION = `
  mutation(
    $projectId: ID!
    $browsers: [BrowserInput]
    $checklist: ChecklistInput
  ) {
    saveProjectReviewMutation(
      input: {
        projectId: $projectId
        browsers: $browsers
        checklist: $checklist
      }
    ) {
      _id
    }
  }
`;

export const COMPLETE_PROJECT_REVIEW_MUTATION = `
  mutation(
    $projectId: ID!
    $status: String
    $browsers: [BrowserInput]
    $checklist: ChecklistInput
  ) {
    completeProjectReviewMutation(
      input: {
        projectId: $projectId
        status: $status
        browsers: $browsers
        checklist: $checklist
      }
    ) {
      _id
    }
  }
`;
