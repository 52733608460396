import projectRules from "./rules/project";
import clientRules from "./rules/client";
import browserRules from "./rules/browser";

export default () => {
  return {
    validateNewProject: projectRules,
    validateNewClient: clientRules,
    validateBrowser: browserRules
  };
};
