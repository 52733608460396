import React, { useContext } from "react";
import { withRouter, Link } from "react-router-dom";

// Styles
import "./ProjectMenu.scss";

// Store
import { Context } from "../../../store";

const ProjectMenu = ({ match }) => {
  const { state } = useContext(Context);
  return (
    <div className="project-menu">
      <ul>
        <li className={match.path === "/" ? "selected" : ""}>
          <Link to="/">All</Link>{" "}
          {match.path === "/" && `(${state.projects.length})`}
        </li>
        <li className={match.path === "/archived" ? "selected" : ""}>
          <Link to="/archived">Archived</Link>{" "}
          {match.path === "/archived" && `(${state.projects.length})`}
        </li>
        <li className={match.path === "/user" ? "selected" : ""}>
          <Link to="/user">My projects</Link>{" "}
          {match.path === "/user" && `(${state.projects.length})`}
        </li>
      </ul>
    </div>
  );
};

ProjectMenu.displayName = "ProjectMenu";

export default withRouter(ProjectMenu);
