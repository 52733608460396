import React, { useContext, useEffect } from "react";
import { GraphQLClient } from "graphql-request";
import { withRouter } from "react-router-dom";

// Styles
import "./ReadOnly.scss";

// Components
import Logo from "../../components/Headers/Logo";
import Loader from "../../components/Loader";
import Summary from "../Review/Summary";
import Employees from "../Review/Employees";

// Store
import { Context } from "../../store";
import { GET_READ_ONLY_PROJECT_ACTION } from "../../store/actions/projects";

// GraphQL
import { ENDPOINT } from "../../graphql/gql";
import { GET_READ_ONLY_PROJECT_QUERY } from "../../graphql/queries/projects";

const ReadOnly = ({ match }) => {
  // proejctid: 5d7782105583f053c7cbeaf1
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    const gqlClient = new GraphQLClient(ENDPOINT);
    getReadOnlyProject(gqlClient);
  }, []);

  const getReadOnlyProject = async gqlClient => {
    const variables = {
      projectId: match.params.id
    };
    const { getReadOnlyProjectQuery } = await gqlClient.request(
      GET_READ_ONLY_PROJECT_QUERY,
      variables
    );
    dispatch({
      type: GET_READ_ONLY_PROJECT_ACTION,
      payload: getReadOnlyProjectQuery
    });
  };

  return (
    <div className="ro">
      {/* Header */}
      <header role="banner">
        <section className="navbar">
          <Logo />
        </section>
      </header>
      <main role="main" className="page readonly">
        {!Array.isArray(state.projects) ? (
          <>
            {/* Summary */}
            <div className="row">
              <div className="col-xs-12">
                <Summary {...state.projects} />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6">
                {/* Checklist */}
                <Checklist {...state.projects.checklist} />
              </div>
              <div className="col-xs-6">
                {/* Employees */}
                <Employees
                  author={state.projects.author}
                  employees={state.projects.employees}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                {/* Browsers */}
                <Browsers browsers={state.projects.browsers} />
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </main>
    </div>
  );
};

ReadOnly.displayName = "ReadOnly";

const Checklist = ({
  readme,
  domain,
  metatags,
  analytics,
  shareImage,
  deployScripts
}) => (
  <div className="checklist">
    <div className="checklist-header">
      <h3 className="checklist-title">Checklist</h3>
    </div>
    <div className="checklist-content">
      <ul className="checklist-options">
        {/* Read me */}
        <li className="checklist-option">
          <span
            className={`checklist-bullet ${
              readme ? "checklist-bullet-checked" : ""
            }`}
          ></span>
          <span className="checklist-option-text">Readme -file</span>
        </li>

        {/* Domain */}
        <li className="checklist-option">
          <span
            className={`checklist-bullet ${
              domain ? "checklist-bullet-checked" : ""
            }`}
          ></span>
          <span className="checklist-option-text">Domain</span>
        </li>

        {/* Meta tags */}
        <li className="checklist-option">
          <span
            className={`checklist-bullet ${
              metatags ? "checklist-bullet-checked" : ""
            }`}
          ></span>
          <span className="checklist-option-text">Meta tags</span>
        </li>

        {/* Analytics */}
        <li className="checklist-option">
          <span
            className={`checklist-bullet ${
              analytics ? "checklist-bullet-checked" : ""
            }`}
          ></span>
          <span className="checklist-option-text">Analytics</span>
        </li>

        {/* Share image */}
        <li className="checklist-option">
          <span
            className={`checklist-bullet ${
              shareImage ? "checklist-bullet-checked" : ""
            }`}
          ></span>
          <span className="checklist-option-text">Share image</span>
        </li>

        {/* Deploy scripts */}
        <li className="checklist-option">
          <span
            className={`checklist-bullet ${
              deployScripts ? "checklist-bullet-checked" : ""
            }`}
          ></span>
          <span className="checklist-option-text">Deploy scripts</span>
        </li>
      </ul>
    </div>
  </div>
);

Checklist.displayName = "Checklist";

const Browsers = ({ browsers }) => (
  <div className="browsers">
    <div className="browsers-header">
      <h3 className="browsers-title">Browsers</h3>
    </div>

    {/* Browserlist */}
    <div className="approve-browsers">
      {/* Labels */}
      <div className="browser-labels">
        <div className="row">
          <div className="col-xs-3">
            <span className="browser-label browser-label-name">Name</span>
          </div>
          <div className="col-xs-3">
            <span className="browser-label browser-label-os">OS</span>
          </div>
          <div className="col-xs-3">
            <span className="browser-label browser-label-os">Type</span>
          </div>
          <div className="col-xs-3">
            <span className="browser-label browser-label-os">Version</span>
          </div>
        </div>
      </div>

      {/* Values */}
      <div className="browser-list">
        {browsers &&
          browsers.map((browser, index) => (
            <div className="row" key={index}>
              <div className="col col-xs-3">
                <span
                  className={`browser-bullet ${
                    browser.approved ? "browser-bullet-checked" : ""
                  }`}
                ></span>
                <span className="browser-detail">{browser.name}</span>
              </div>
              <div className="col col-xs-3">
                <span className="browser-detail">{browser.os}</span>
              </div>
              <div className="col col-xs-3">
                <span className="browser-detail">{browser.type}</span>
              </div>
              <div className="col col-xs-3">
                <span className="browser-detail">{browser.version}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
);

Browsers.displayName = "Browsers";

export default withRouter(ReadOnly);
