import React, { useContext, useReducer } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";

// Styles
import "./styles/index.scss";

// Store
import { Context, Reducer } from "./store";

// Components
import Login from "./pages/Login";
import ReadOnly from "./pages/ReadOnly";

import Home from "./pages/Home";
import User from "./pages/User";
import Archived from "./pages/Archived";

import NewProject from "./pages/NewProject";
import NewClient from "./pages/NewClient";
import Review from "./pages/Review";
import Search from "./pages/Search";

// History
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(Context);
  return (
    <Route
      render={props => {
        return !state.auth ? <Login /> : <Component {...props} />;
      }}
      {...rest}
    />
  );
};

const Root = () => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Router history={browserHistory}>
      <Context.Provider value={{ state, dispatch }}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/p/:id" component={ReadOnly} exact />
          <ProtectedRoute path="/" component={Home} exact />
          <ProtectedRoute path="/archived" component={Archived} />
          <ProtectedRoute path="/user" component={User} />
          <ProtectedRoute path="/new/project" component={NewProject} />
          <ProtectedRoute path="/new/client" component={NewClient} />
          <ProtectedRoute path="/review/:id" component={Review} />
          <ProtectedRoute path="/q/:id" component={Search} />
        </Switch>
      </Context.Provider>
    </Router>
  );
};

ReactDOM.render(<Root />, document.querySelector("#root"));
