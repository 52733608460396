import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

// Styles
import "./Cards.scss";

// Store
import { Context } from "../../../store";

// Components
import Author from "../Author";
import Actions from "../Actions";
import Loader from "../../Loader";

const Card = ({ userId, project }) => (
  <div className="col-xs-2">
    <div className="card">
      <div className={`card-top card-status card-status-${project.status}`}>
        {/* Author */}
        <Author owner={project.author._id === userId} />

        {/* Actions */}
        {<Actions project={project} />}
      </div>
      <div className="card-bottom">
        {/* Client name */}
        <div className="client-name">{project.client.name}</div>

        {/* Project name */}
        <div className="project-name">
          <Link to={`/p/${project._id}`}>{project.name}</Link>
        </div>

        <div className="card-footer">
          {/* Last modified */}
          <div className="updated-at">
            {format(Number(project.updatedAt), "MMM d")}
          </div>
          <div className="employees">
            {project.employees.map(({ user: { picture } }, index) => (
              <span
                key={index}
                className="person-bubble"
                style={{ backgroundImage: `url(${picture})` }}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Card.displayName = "Card";

const Cards = () => {
  const { state } = useContext(Context);
  return (
    <div className="cards">
      <div className="row">
        {state.projects ? (
          state.projects.map((project, index) => (
            <Card key={index} project={project} userId={state.user._id} />
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

Cards.displayName = "Cards";

export default Cards;
