import React from "react";

// Styles
import "./Author.scss";

const Author = ({ owner }) => (
  <div className="author">
    <span className="material-icons">{owner ? `star` : `star_border`}</span>
  </div>
);

Author.displayName = "Author";

export default Author;
