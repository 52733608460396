import { isEmptyString } from "./rules";

export default ({ name }) => {
  const errors = {};

  // name
  if (!name || isEmptyString(name)) {
    errors.name = "Name is required.";
  }

  return errors;
};
