import { GraphQLClient } from "graphql-request";

export const ENDPOINT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : "http://localhost:4000/graphql";

export const useClient = () => {
  const idToken = window.gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .getAuthResponse().id_token;

  return new GraphQLClient(ENDPOINT, {
    headers: {
      Authorization: idToken
    }
  });
};
