export const GET_READ_ONLY_PROJECT_ACTION = "GET_READ_ONLY_PROJECT_ACTION";

export const GET_PROJECT_ACTION = "GET_PROJECT_ACTION";
export const GET_PROJECTS_ACTION = "GET_PROJECTS_ACTION";

export const CREATE_PROJECT_ACTION = "CREATE_PROJECT_ACTION";
export const DELETE_PROJECT_ACTION = "DELETE_PROJECT_ACTION";
export const ARCHIVE_PROJECT_ACTION = "ARCHIVE_PROJECT_ACTION";

export const SHOW_PROJECTS_BY_TYPE_ACTION = "SHOW_PROJECTS_BY_TYPE_ACTION";

export const SET_PROJECT_TO_REVIEW_ACTION = "SET_PROJET_TO_REVIEW_ACTION";

export const SEARCH_PROJECTS_BY_CLIENT_ACTION =
  "SEARCH_PROJECTS_BY_CLIENT_ACTION";
