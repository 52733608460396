export const GET_USER_QUERY = `
  {
    user {
      _id
      name
      email
      picture
    }
  }
`;
