import React from "react";

// Styles
import "./Button.scss";

const Button = ({ type, classNames, onClick, disabled, children }) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={classNames}
  >
    {children}
  </button>
);

Button.displayName = "Button";

export default Button;
