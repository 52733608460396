import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Styles
import "./Review.scss";

// Components
import Navbar from "../../components/Headers/Navbar";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import Summary from "./Summary";
import Checklist from "./Checklist";
import Employees from "./Employees";
import Browsers from "./Browsers";

// Store
import { Context } from "../../store";
import { GET_PROJECT_ACTION } from "../../store/actions/projects";
import {
  SAVE_PROJECT_REVIEW_MUTATION,
  COMPLETE_PROJECT_REVIEW_MUTATION
} from "../../graphql/mutations/projects";

// GraphQL
import { useClient } from "../../graphql/gql";
import { GET_REVIEW_PROJECT_QUERY } from "../../graphql/queries/projects";

const Review = ({ match, history }) => {
  const gqlClient = useClient();
  const { state, dispatch } = useContext(Context);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checklist, updateChecklist] = useState({});
  const [browsers, updateBrowsers] = useState([]);

  useEffect(() => {
    getReviewProject();
  }, []);

  const getReviewProject = async () => {
    const variables = {
      projectId: match.params.id
    };
    const { getReviewProjectQuery } = await gqlClient.request(
      GET_REVIEW_PROJECT_QUERY,
      variables
    );

    // update local state from query
    const {
      browsers: browsersQuery,
      checklist: checklistQuery
    } = getReviewProjectQuery;

    Object.keys(checklistQuery).map(key =>
      populateChecklist({
        name: key,
        value: checklistQuery[key]
      })
    );

    browsersQuery.map(addBrowser);

    // dispatch to store...
    dispatch({
      type: GET_PROJECT_ACTION,
      payload: getReviewProjectQuery
    });
  };

  const discardProjectReview = () => {
    history.push("/");
  };

  const saveProjectReview = async () => {
    setIsSubmitting(true);
    try {
      const variables = {
        browsers,
        checklist,
        status: "review",
        projectId: match.params.id
      };
      await gqlClient.request(SAVE_PROJECT_REVIEW_MUTATION, variables);
      setIsSubmitting(false);
      history.push("/");
    } catch (err) {
      setIsSubmitting(false);
      throw new Error(err);
    }
  };

  const completeProjectReview = async () => {
    setIsSubmitting(true);
    try {
      const variables = {
        browsers,
        checklist,
        status: "completed",
        projectId: match.params.id
      };
      await gqlClient.request(COMPLETE_PROJECT_REVIEW_MUTATION, variables);
      setIsSubmitting(false);
      history.push("/");
    } catch (err) {
      setIsSubmitting(false);
      throw new Error(err);
    }
  };

  const populateChecklist = event => {
    updateChecklist(checklist => ({
      ...checklist,
      [event.name]: Boolean(event.value)
    }));
  };

  const addBrowser = browser => {
    updateBrowsers(browsers => [...browsers, browser]);
  };

  const dropBrowser = browserId => {
    const updatedBrowsers = browsers.filter(
      browser => browser.bid !== browserId
    );
    updateBrowsers(browsers => [...updatedBrowsers]);
  };

  const approveBrowser = browserId => {
    const updatedBrowsers = browsers.map(browser => {
      if (browser.bid === browserId) {
        browser.approved = !browser.approved;
      }
      return browser;
    });
    updateBrowsers(browsers => [...updatedBrowsers]);
  };

  return (
    <>
      <header role="banner">
        <Navbar />
      </header>
      <main role="main" className="page review">
        <div className="page-header">
          <h2 className="page-title">Review project</h2>
        </div>
        <div className="page-content">
          {/*
            state.projects is an array and will be replaced
            with single object when request cycle completes,
            so wait for it to happen...
          */}
          {!Array.isArray(state.projects) ? (
            <form>
              {/* Summary */}
              <div className="row">
                <div className="col-xs-12">
                  <Summary {...state.projects} />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-6">
                  {/* Checklist */}
                  <Checklist onChange={populateChecklist} values={checklist} />
                </div>
                <div className="col-xs-6">
                  {/* Employees */}
                  <Employees
                    author={state.projects.author}
                    employees={state.projects.employees}
                  />
                </div>
              </div>

              {/* Browsers */}
              <div className="row">
                <div className="col-xs-12">
                  <Browsers
                    browsers={browsers}
                    addBrowser={addBrowser}
                    dropBrowser={dropBrowser}
                    approveBrowser={approveBrowser}
                  />
                </div>
              </div>

              {/* Actions */}
              <div className="row">
                <div className="col-xs-12">
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    classNames="btn btn-cyan"
                    onClick={completeProjectReview}
                  >
                    Mark as reviewed
                  </Button>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    classNames="btn btn-yellow"
                    onClick={saveProjectReview}
                  >
                    Save and exit
                  </Button>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    classNames="btn btn-purple"
                    onClick={discardProjectReview}
                  >
                    Discard changes
                  </Button>
                </div>
              </div>
            </form>
          ) : (
            <Loader />
          )}
        </div>
      </main>
    </>
  );
};

Review.displayName = "Review";

export default withRouter(Review);
