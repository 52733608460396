import React from "react";
import ReactDOM from "react-dom";

// Styles
import "./Modal.scss";

// Components
import Button from "../Button";

const Modal = ({ children, states: { success, cancel } }) =>
  ReactDOM.createPortal(
    <div className="modal-root">
      <div className="modal-backdrop"></div>
      <div className="modal">
        <div className="modal-content">{children}</div>
        <div className="modal-footer">
          <Button
            type="button"
            onClick={success.action}
            classNames="btn btn-cyan"
          >
            {success.text}
          </Button>
          <Button
            type="button"
            onClick={cancel.action}
            classNames="btn btn-purple"
          >
            {cancel.text}
          </Button>
        </div>
      </div>
    </div>,
    document.querySelector("#portal")
  );

Modal.displayName = "Modal";

export default Modal;
