import React, { useState } from "react";
import _ from "lodash";

import "./Employees.scss";

const Picture = ({ name, url }) => {
  const [visible, setVisibility] = useState(false);

  const showName = () => {
    setVisibility(true);
  };

  const hideName = () => {
    setVisibility(false);
  };

  return (
    <div className="person">
      <span
        className="person-bubble"
        onMouseEnter={showName}
        onMouseLeave={hideName}
        style={{ backgroundImage: `url(${url})` }}
      />
      {visible && <span className="person-name">{name}</span>}
    </div>
  );
};

Picture.displayName = "Picture";

const Group = ({ people, title }) => {
  return (
    <>
      <h4 className="employee-type">{title}</h4>
      <div className="employee-images">
        {people.map(({ user: { name, picture } }, index) => (
          <Picture key={index} url={picture} name={name} />
        ))}
      </div>
    </>
  );
};

Group.displayName = "Group";

const groupByRole = data => {
  return _.chain(data)
    .groupBy("role")
    .toPairs()
    .map(pairs => _.zipObject(["role", "users"], pairs))
    .value();
};

const pickUsers = (data, role) => {
  return _.result(
    _.find(data, doc => {
      return doc.role === role;
    }),
    "users"
  );
};

const Employees = ({ author, employees }) => {
  const people = groupByRole(employees);
  const coders = pickUsers(people, "coder");
  const designers = pickUsers(people, "designer");
  const projectManagers = pickUsers(people, "project manager");
  const reviewers = pickUsers(people, "reviewer");
  return (
    <div className="employees">
      <div className="employees-header">
        <h3 className="employees-title">Employees</h3>
      </div>
      <div className="employees-content">
        <ul className="employees-list">
          {/* Author */}
          <li className="employees-list-item">
            <h4 className="employee-type">Author</h4>
            <div className="employee-images">
              <Picture url={author.picture} name={author.name} />
            </div>
          </li>
          {/* Coders */}
          {coders && (
            <li className="employees-list-item">
              <Group people={coders} title="Coders" />
            </li>
          )}
          {/* Designers */}
          {designers && (
            <li className="employees-list-item">
              <Group people={designers} title="Designers" />
            </li>
          )}
          {/* Project Managers */}
          {projectManagers && (
            <li className="employees-list-item">
              <Group people={projectManagers} title="Project Managers" />
            </li>
          )}
          {/* Reviewers */}
          {reviewers && (
            <li className="employees-list-item">
              <Group people={reviewers} title="Reviewers" />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

Employees.displayName = "Employees";

export default Employees;
