// Reducers
import userReducer from "./user";
import layoutReducer from "./layout";
import clientsReducer from "./clients";
import projectsReducer from "./projects";
import employeesReducer from "./employees";

const reducers = {
  ...userReducer,
  ...layoutReducer,
  ...clientsReducer,
  ...projectsReducer,
  ...employeesReducer
};

export default (state, payload) => {
  return reducers[payload.type](state, payload);
};
