import React, { useContext, useEffect } from "react";

// Styles
import "./User.scss";

// Components
import Layout from "../../components/Projects/Layout";
import Navbar from "../../components/Headers/Navbar";
import Toolbar from "../../components/Headers/Toolbar";
import AddMenu from "../../components/Menus/AddMenu";

// Store
import { Context } from "../../store";
import { GET_PROJECTS_ACTION } from "../../store/actions/projects";

// GraphQL
import { useClient } from "../../graphql/gql";
import { GET_USER_PROJECTS_QUERY } from "../../graphql/queries/projects";

const User = () => {
  const gqlClient = useClient();
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    getUserProject();
  }, []);

  const getUserProject = async () => {
    const variables = {
      userId: state.user._id
    };
    const { userProjectsQuery } = await gqlClient.request(
      GET_USER_PROJECTS_QUERY,
      variables
    );
    dispatch({
      type: GET_PROJECTS_ACTION,
      payload: userProjectsQuery
    });
  };

  return (
    <>
      <header role="banner">
        <Navbar showProjectMenu={true} />
        <Toolbar />
      </header>
      <main role="main">
        <Layout />
        <AddMenu />
      </main>
    </>
  );
};

User.displayName = "User";

export default User;
