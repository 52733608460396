import React, { useEffect, useState } from "react";
import _ from "lodash";

// Style
import "./Employees.scss";

// Components
import Error from "../../../components/Forms/Error";
import Label from "../../../components/Forms/Label";
import DropdownInput from "../../../components/Forms/DropdownInput";

const RemoveIcon = ({ _id, dropEmployee }) => (
  <i
    onClick={() => dropEmployee(_id)}
    className="material-icons remove-employee-icon"
  >
    remove_circle
  </i>
);

RemoveIcon.displayName = "RemoveIcon";

const AddIcon = ({ employee: { name, role }, addNewEmployee }) => (
  <i
    className={`material-icons add-employee-icon ${
      name !== "Name" && role !== "Role" ? "enabled" : ""
    }`}
    onClick={addNewEmployee}
  >
    {name !== "Name" && role !== "Role" ? "add_circle" : "add_circle_outline"}
  </i>
);

AddIcon.displayName = "AddIcon";

const AddedEmployees = ({ employees, dropEmployee }) => {
  return employees.map(({ _id, name, role }, index) => (
    <div className="row" key={index}>
      <div className="col-xs-1">
        <RemoveIcon id={_id} dropEmployee={dropEmployee} />
      </div>
      <div className="col-xs-6">{name}</div>
      <div className="col-xs-5">{_.upperFirst(role)}</div>
    </div>
  ));
};

AddedEmployees.displayName = "AddedEmployees";

const AddEmployee = ({ roles, employees, addEmployee }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("Name");
  const [role, setRole] = useState("Role");

  const addNewEmployee = () => {
    if (name === "Name" || role === "Role") {
      return;
    }
    addEmployee({
      user: id,
      role: role.toLowerCase()
    });
    setId("");
    setName("Name");
    setRole("Role");
  };

  return (
    <div className="row">
      <div className="col-xs-1">
        {/* Add */}
        <AddIcon employee={{ name, role }} addNewEmployee={addNewEmployee} />
      </div>
      <div className="col-xs-6">
        {/* Names */}
        <DropdownInput
          selected={name}
          options={employees}
          defaultValue={name}
          onChange={({ value, label }) => {
            setId(value);
            setName(label);
          }}
        />
      </div>
      <div className="col-xs-5">
        {/* Roles */}
        <DropdownInput
          selected={role}
          options={roles}
          defaultValue={role}
          onChange={({ label }) => {
            setRole(label);
          }}
        />
      </div>
    </div>
  );
};

AddEmployee.displayName = "AddEmployee";

const Employees = ({ error, values, employees, setEmployees }) => {
  const [roles, setRoles] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setRoles(
      ["coder", "designer", "reviewer", "project manager"].map(role => {
        return {
          value: role,
          label: `${role.substr(0, 1).toUpperCase()}${role
            .substr(1)
            .toLowerCase()}`
        };
      })
    );
    setOptions(
      employees.map(employee => {
        return Object.keys(employee).reduce(acc => {
          return {
            value: employee._id,
            label: employee.name
          };
        }, []);
      })
    );
  }, [employees]);

  const addEmployee = employee => {
    setEmployees(employees => [...employees, employee]);
  };

  const dropEmployee = id => {
    setEmployees(employees => {
      return employees.filter(employee => employee._id !== id);
    });
  };

  const sampleEmployees = () => {
    return values.map(({ user, role }) => {
      const { _id, name } = employees.find(({ _id }) => _id === user);
      return { _id, name, role };
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <Label labelFor="employees">Employees</Label>
        </div>
      </div>

      {/* Added employees */}
      {!_.isEmpty(values) && (
        <AddedEmployees
          dropEmployee={dropEmployee}
          employees={sampleEmployees()}
        />
      )}

      {/* Add Employee */}
      <AddEmployee
        roles={roles}
        employees={options}
        addEmployee={addEmployee}
      />

      {error && (
        <Error>
          <div className="col-xs-12">{error}</div>
        </Error>
      )}
    </>
  );
};

Employees.displayName = "Employees";

export default Employees;
