import React, { useEffect, useState } from "react";
import _ from "lodash";

// Style
import "./Client.scss";

// Components
import Error from "../../../components/Forms/Error";
import DropdownInput from "../../../components/Forms/DropdownInput";

const Client = ({ error, value, clients, onChange }) => {
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(
      clients.map(client => {
        return Object.keys(client).reduce(acc => {
          return {
            ...acc,
            ...{
              value: client._id,
              label: client.name
            }
          };
        }, {});
      })
    );
  }, [clients]);

  const selectClient = ({ label, value }) => {
    onChange({
      _id: value,
      name: label
    });
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        {!_.isEmpty(options) && (
          <DropdownInput
            selected={
              !value
                ? ""
                : {
                    label: value.name
                  }
            }
            defaultValue={"Client Oy"}
            onChange={selectClient}
            options={options}
          />
        )}
      </div>
      {error && (
        <Error>
          <div className="col-xs-12">{error}</div>
        </Error>
      )}
    </div>
  );
};

Client.displayName = "Client";

export default Client;
