import React, { useState } from "react";

// Styles
import "./DropdownInput.scss";

const DropdownInput = ({
  selected: { label },
  defaultValue,
  onChange,
  options
}) => {
  const [open, changeState] = useState(false);
  return (
    <div className="dropdown">
      <div className="dropdown-value" onClick={() => changeState(!open)}>
        {label ? label : defaultValue}
        <span className="dropdown-icon">&#x25BE;</span>
      </div>
      <ul className={`dropdown-list${open ? " open" : ""}`}>
        {options.map((option, index) => (
          <li
            key={index}
            onClick={() => {
              onChange(option);
              changeState(!open);
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

DropdownInput.displayName = "DropdownInput";

export default DropdownInput;
