export const CREATE_CLIENT_MUTATION = `
  mutation($name: String!) {
    newClientMutation(
      input: {
        name: $name
      }
    ) {
      _id
    }
  }
`;
