import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

// Styles
import "./NewClient.scss";

// Hooks
import useValidation from "../../hooks/useValidation";

// GraphQL
import { useClient } from "../../graphql/gql";
import { CREATE_CLIENT_MUTATION } from "../../graphql/mutations/clients";

// Components
import Navbar from "../../components/Headers/Navbar";
import Button from "../../components/Button";
import Error from "../../components/Forms/Error";
import Label from "../../components/Forms/Label";
import TextInput from "../../components/Forms/TextInput";

const NewClient = ({ history }) => {
  const gqlClient = useClient();
  const [name, setName] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { validateNewClient } = useValidation();

  useEffect(() => {
    if (_.isEmpty(errors) && isSubmitting) {
      createNewClient();
    }
    setIsSubmitting(false);
  }, [errors]);

  const handleDiscard = () => {
    history.push("/");
  };

  const handleSubmit = event => {
    event.preventDefault();
    const values = { name };
    setIsSubmitting(true);
    setErrors(validateNewClient(values));
  };

  const createNewClient = async () => {
    try {
      const variables = { name };
      await gqlClient.request(CREATE_CLIENT_MUTATION, variables);
      history.push("/");
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <>
      <header role="banner">
        <Navbar />
      </header>
      <main role="main" className="page new-client">
        <h2 className="page-title">New Client</h2>
        <div className="page-content">
          <form className="form">
            {/* Client name */}
            <div className="row">
              <div className="col-xs-12">
                <Label labelFor="name">Client name</Label>
                <TextInput
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Client Oy"
                  onChange={e => setName(e.target.value)}
                />
              </div>
              {errors.name && (
                <Error>
                  <div className="col-xs-12">{errors.name}</div>
                </Error>
              )}
            </div>

            {/* Buttons */}
            <div className="row">
              <div className="col-xs-12">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  classNames="btn btn-cyan"
                >
                  Create
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleDiscard}
                  classNames="btn btn-purple"
                >
                  Discard
                </Button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

NewClient.displayName = "NewClient";

export default withRouter(NewClient);
