import React from "react";

// Styles
import "./Label.scss";

const Label = ({ labelFor, children }) => (
  <label htmlFor={labelFor}>{children}</label>
);

Label.displayName = "Label";

export default Label;
