import { fromUnixTime, differenceInCalendarDays } from "date-fns";
import { diff } from "jest-matcher-utils";

export const daysLeftBeforeDeadline = unix => {
  const difference = differenceInCalendarDays(fromUnixTime(unix), new Date());
  if (difference > 0) {
    return `In ${difference} days`;
  } else if (difference === 0) {
    return "Today";
  } else {
    return "Overdue";
  }
};
