import React, { useContext, useEffect } from "react";

// Styles
import "./Home.scss";

// Components
import Layout from "../../components/Projects/Layout";
import Navbar from "../../components/Headers/Navbar";
import Toolbar from "../../components/Headers/Toolbar";
import AddMenu from "../../components/Menus/AddMenu";

// Store
import { Context } from "../../store";
import { GET_PROJECTS_ACTION } from "../../store/actions/projects";

// GraphQL
import { useClient } from "../../graphql/gql";
import { GET_ACTIVE_PROJECTS_QUERY } from "../../graphql/queries/projects";

const Home = () => {
  const gqlClient = useClient();
  const { dispatch } = useContext(Context);

  useEffect(() => {
    getActiveProject();
  }, []);

  const getActiveProject = async () => {
    const { activeProjectsQuery } = await gqlClient.request(
      GET_ACTIVE_PROJECTS_QUERY
    );
    dispatch({
      type: GET_PROJECTS_ACTION,
      payload: activeProjectsQuery
    });
  };

  return (
    <>
      <header role="banner">
        <Navbar showProjectMenu={true} />
        <Toolbar />
      </header>
      <main role="main">
        <Layout />
        <AddMenu />
      </main>
    </>
  );
};

Home.displayName = "Home";

export default Home;
