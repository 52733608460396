import { isEmptyString } from "./rules";

export default ({ name, os, type, version }) => {
  const errors = {};

  // name
  if (!name || isEmptyString(name)) {
    errors.name = "Name is required.";
  }

  // os
  if (!os || isEmptyString(os)) {
    errors.os = "OS is required.";
  }

  // type
  if (!type || isEmptyString(type)) {
    errors.type = "Type is required.";
  }

  // version
  if (!version || isEmptyString(version)) {
    errors.version = "Version is required.";
  }

  return errors;
};
