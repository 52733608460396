import React from "react";

// Styles
import "./Loader.scss";

const Loader = () => {
  return <div className="loader" />;
};

Loader.displayName = "Loader";

export default Loader;
