import React, { useState } from "react";

// Styles
import "./Browsers.scss";

// Components
import Switch from "../../../components/Forms/Switch";
import TextInput from "../../../components/Forms/TextInput";

const Browsers = ({ addBrowser, dropBrowser, approveBrowser, browsers }) => {
  const [values, setValues] = useState({});

  const updateInput = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const updateBrowserList = () => {
    addBrowser({
      ...values,
      approved: false,
      bid: new Date().getTime().toString()
    });
    setValues({});
  };

  return (
    <div className="browsers">
      <div className="browsers-header">
        <h3 className="browsers-title">Browsers</h3>
      </div>

      {/* Add browsers */}
      <div className="add-browsers">
        <div className="row">
          {/* Icon */}
          <div className="col-xs-1">
            <i
              className={`material-icons add-browser-icon ${
                !values.name || !values.os || !values.type || !values.version
                  ? ""
                  : "enabled"
              }`}
              onClick={updateBrowserList}
            >
              {!values.name || !values.os || !values.type || !values.version
                ? "add_circle_outline"
                : "add_circle"}
            </i>
          </div>
          {/* Name */}
          <div className="col-xs-3">
            <TextInput
              type="text"
              name="name"
              value={values.name || ""}
              placeholder="Chrome"
              onChange={updateInput}
            />
          </div>

          {/* OS */}
          <div className="col-xs-2">
            <TextInput
              type="text"
              name="os"
              value={values.os || ""}
              placeholder="Mac"
              onChange={updateInput}
            />
          </div>

          {/* Type */}
          <div className="col-xs-3">
            <TextInput
              type="text"
              name="type"
              value={values.type || ""}
              placeholder="Desktop"
              onChange={updateInput}
            />
          </div>

          {/* Version */}
          <div className="col-xs-3">
            <TextInput
              type="text"
              name="version"
              value={values.version || ""}
              placeholder="Latest"
              onChange={updateInput}
            />
          </div>
        </div>
      </div>

      {/* Approve browsers */}
      {browsers.length > 0 && (
        <div className="approve-browsers">
          {/* Labels */}
          <div className="browser-labels">
            <div className="row">
              <div className="col-xs-3">
                <span className="browser-label browser-label-name">Name</span>
              </div>
              <div className="col-xs-2">
                <span className="browser-label browser-label-os">OS</span>
              </div>
              <div className="col-xs-3">
                <span className="browser-label browser-label-type">Type</span>
              </div>
              <div className="col-xs-2">
                <span className="browser-label browser-label-version">
                  Version
                </span>
              </div>
              <div className="col-xs-2">
                <span className="browser-label browser-label-approved">
                  Approved
                </span>
              </div>
            </div>
          </div>

          {/* Browserlist */}
          <div className="browser-list">
            {browsers.map((browser, index) => (
              <div className="row" key={index}>
                <div className="col col-xs-3">
                  <i
                    className="material-icons remove-browser-icon"
                    onClick={() => dropBrowser(browser.bid)}
                  >
                    remove_circle
                  </i>
                  <span className="browser-detail">{browser.name}</span>
                </div>
                <div className="col col-xs-2">
                  <span className="browser-detail">{browser.os}</span>
                </div>
                <div className="col col-xs-3">
                  <span className="browser-deta">{browser.type}</span>
                </div>
                <div className="col col-xs-2">
                  <span className="browser-detail">{browser.version}</span>
                </div>
                <div className="col col-xs-2">
                  <Switch
                    name="approved"
                    value={browser.approved}
                    onChange={() => approveBrowser(browser.bid)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Browsers.displayName = "Browsers";

export default Browsers;
