export const GET_ACTIVE_PROJECTS_QUERY = `
  {
    activeProjectsQuery {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          _id
          picture
        }
      }
      status
      deadline
      archived
    }
  }
`;

export const GET_ARCHIVED_PROJECTS_QUERY = `
  {
    archivedProjectsQuery {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          _id
          picture
        }
      }
      status
      deadline
      archived
    }
  }
`;

export const GET_USER_PROJECTS_QUERY = `
  query($userId: ID!) {
    userProjectsQuery(userId: $userId) {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          _id
          picture
        }
      }
      status
      deadline
      archived
    }
  }
`;

export const GET_REVIEW_PROJECT_QUERY = `
  query($projectId: ID!) {
    getReviewProjectQuery(projectId: $projectId) {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          _id
          name
          picture
        }
      }
      status
      archived
      notify
      deadline
      bitbucketId
      productionUrl
      stagingUrl
      checklist {
        analytics
        readme
        domain
        metatags
        shareImage
        deployScripts
      }
      browsers {
        bid
        name
        os
        type
        version
        approved
      }
    }
  }
`;

export const GET_READ_ONLY_PROJECT_QUERY = `
  query($projectId: ID!) {
    getReadOnlyProjectQuery(projectId: $projectId) {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          _id
          name
          picture
        }
      }
      status
      archived
      notify
      deadline
      bitbucketId
      productionUrl
      stagingUrl
      checklist {
        analytics
        readme
        domain
        metatags
        shareImage
        deployScripts
      }
      browsers {
        bid
        name
        os
        type
        version
        approved
      }
    }
  }
`;

export const SEARCH_PROJECTS_BY_CLIENT_QUERY = `
  query($clientId: ID!) {
    searchProjectsByClientQuery(clientId: $clientId) {
      _id
      createdAt
      updatedAt
      name
      client {
        _id
        name
      }
      author {
        _id
        name
        picture
      }
      employees {
        role
        user {
          _id
          name
          picture
        }
      }
      status
      deadline
      archived
    }
  }
`;
