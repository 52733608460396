import { isEmptyArray, isEmptyString } from "./rules";
import { isValid, parse } from "date-fns";

export default ({
  name,
  client,
  bitbucketId,
  productionUrl,
  stagingUrl,
  deadline,
  employees
}) => {
  const errors = {};

  // projectName
  if (!name || isEmptyString(name)) {
    errors.name = "Name is required.";
  }

  // clientName
  if (!client || Object.entries(client).length === 0) {
    errors.client = "Client is required.";
  }

  // bitbucketId
  if (!bitbucketId || isEmptyString(bitbucketId)) {
    errors.bitbucketId = "Bitbucket ID is required.";
  }

  // productionUrl
  if (!productionUrl || isEmptyString(productionUrl)) {
    errors.productionUrl = "Production URL is required.";
  }

  // stagingUrl
  if (!stagingUrl || isEmptyString(stagingUrl)) {
    errors.stagingUrl = "Staging URL is required.";
  }

  // deadline
  if (!deadline) {
    errors.deadline = "Deadline is required.";
  } else if (deadline && !isValid(parse(deadline, "dd.MM.yyyy", new Date()))) {
    errors.deadline = "Deadline is invalid date.";
  }

  // employees
  if (!employees || isEmptyArray(employees)) {
    errors.employees = "At least one employee must be included.";
  }

  return errors;
};
