import React from "react";

// Styles
import "./Toolbar.scss";

// Components
import LayoutSwitch from "../LayoutSwitch";

const Toolbar = () => (
  <section className="toolbar">
    <LayoutSwitch />
  </section>
);

Toolbar.displayName = "Toolbar";

export default Toolbar;
