import React, { useContext } from "react";
import { GoogleLogout } from "react-google-login";

// Styles
import "./UserMenu.scss";

// Store
import { Context } from "../../../store";
import { LOGOUT_USER_ACTION } from "../../../store/actions/user";

const UserMenu = () => {
  const { state, dispatch } = useContext(Context);

  const logoutUser = () => {
    dispatch({
      type: LOGOUT_USER_ACTION
    });
  };

  return (
    <div className="user-menu">
      <ul>
        <li className="current-user">
          <span
            className="person-bubble"
            style={{ backgroundImage: `url(${state.user.picture})` }}
          />
        </li>
        <li className="logout">
          <GoogleLogout
            onLogoutSuccess={logoutUser}
            render={({ onClick }) => (
              <i className="material-icons" onClick={onClick}>
                exit_to_app
              </i>
            )}
          />
        </li>
      </ul>
    </div>
  );
};

UserMenu.displayName = "UserMenu";

export default UserMenu;
