import React, { useContext } from "react";

// Styles
import "./Layout.scss";

// Components
import Loader from "../../../components/Loader";
import ProjectsList from "../List";
import ProjectsCards from "../Cards";

// Store
import { Context } from "../../../store";

const Layout = () => {
  const { state } = useContext(Context);

  // Projects still beign fetched...
  if (!Array.isArray(state.projects)) {
    return (
      <div className="projects">
        <Loader />
      </div>
    );
  }

  // Projects exits
  if (state.projects.length > 0) {
    // Show by layout
    return (
      <div className="projects">
        {state.layout === "list" ? <ProjectsList /> : <ProjectsCards />}
      </div>
    );
  } else {
    return (
      <div className="projects">
        <div>No projects.</div>
      </div>
    );
  }
};

Layout.displayName = "Layout";

export default Layout;
