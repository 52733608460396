import React from "react";
import { withRouter } from "react-router-dom";

// Styles
import "./AddMenu.scss";

// Components
import FloatingMenu from "../FloatingMenu";

// Menu
const menu = ["Project", "Client"];

const AddButton = ({ changeMenuState, menuState }) => {
  const handleButtonClick = () => {
    changeMenuState();
  };
  return (
    <div className="fixed-action-btn">
      <button
        className={`btn btn-cyan btn-round${menuState ? " btn-rotate" : ""}`}
        onClick={handleButtonClick}
      >
        <i className="material-icons">add</i>
      </button>
    </div>
  );
};

AddButton.displayName = "AddButton";

const AddMenu = ({ history }) => {
  return (
    <div className="add-menu">
      {/* Menu */}
      <FloatingMenu
        menuItems={menu.map(item => {
          return {
            text: item,
            action: ({ text }) => {
              history.push(`/new/${text.toLowerCase()}`);
            }
          };
        })}
        MenuButton={withRouter(AddButton)}
      />
    </div>
  );
};

AddMenu.displayName = "AddMenu";

export default withRouter(AddMenu);
